'use client'
import dynamic from 'next/dynamic'
import {GlobalSettings} from "@/configuration/global-settings";

const TopSliderCarousel = dynamic(() => import("@/components/shared/top-slider-carousel"));
import {
    Card,
    CardHeader,
    Image,
    Tab,
    Tabs
} from "@nextui-org/react";
import SharedServiceInstance from "@/services/shared-service";
import NextImage from "next/image";
import {useEffect, useState} from "react";
import {IconButton} from "@material-tailwind/react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import Swipe from "react-easy-swipe";
import Link from "next/link";
import LoadingCommon from "@/components/shared/loadingCommon";
import SharedService from "@/services/shared-service";

export default function TopSlider({data, banners}) {
    return (
        <div className="max-w-full">
            <Banner data={data.value}/>
            <TopSliderCarousel images={banners}/>
        </div>
    );
}

function Banner({data}) {
    const sharedService = SharedServiceInstance;
    const [selected, setSelected] = useState("0");
    const [animated, setAnimated] = useState(true);
    const [pointer, setPointer] = useState(false);

    const handleNext = () => {
        let temp = parseInt(selected) + 1;
        setAnimated(false);
        if (temp >= data.length) {
            setSelected("0");
        } else {
            setSelected(temp.toString());
        }
    }

    const handlePrev = () => {
        let temp = parseInt(selected) - 1;
        setAnimated(false);
        if (temp < 0) {
            setSelected("4");
        } else {
            setSelected(temp.toString());
        }
    }

    function handleMouseLeave() {
        setAnimated(true);
    }

    useEffect(function () {
        if(!pointer) {
            let timer = setInterval(function () {
                return handleNext()
            }, 10000);
            return function () {
                return clearInterval(timer)
            }
        }
    }, [handleNext, pointer]);

    function handleMouseEnterContent(index) {
        setSelected(index.toString());
    }

    return (
        data &&
        <div className="flex flex-col" onMouseEnter={() => setPointer(true)} onMouseLeave={() => setPointer(false)}>
            <Tabs
                aria-label="Options"
                selectedKey={selected}
                disableAnimation={true}
                color="primary"
                variant="underlined"
                onSelectionChange={setSelected}
                classNames={{
                    base: "order-last",
                    tabList: "w-full relative rounded-b-[5px] p-0 md:gap-0 gap-2 justify-center bg-white",
                    // cursor: "w-full bg-primary/50",
                    tab: "max-w-fit px-0 h-full after:md:h-[5px] after:hidden after:md:block after:w-[100%] after:left-0",
                    tabContent: "whitespace-normal text-justify",
                    panel: "pt-3 pb-0 px-0"
                }}
            >
                {
                    data.map((data, index) => {
                        if (index < 5) {
                            return (
                                <Tab key={index} title={
                                    <>
                                        <div
                                            className={`sm:hidden rounded-full ${parseInt(selected) === index ? 'w-[16px] h-[8px] bg-primary' : 'w-[8px] h-[8px] bg-gray-600'} animate-shake duration-300`}></div>
                                        <Link href={SharedService.getPostPath(data)} className="hidden md:flex md:py-5 items-center" onMouseEnter={() => handleMouseEnterContent(index)}>
                                            <div
                                                className={`flex flex-col ${parseInt(selected) === index ? 'text-primary' : ''} ${index > 0 ? 'border-l-2 border-black' : ''} px-5`}>
                                                <p className="md:text-medium line-clamp-1 font-bold">
                                                    <span>{data.categories.find(c => c.code === data.categoryCode).name.toUpperCase()}</span>
                                                </p>
                                                <p className="text-sm md:text-medium font-bold line-clamp-2">{data.title}</p>
                                                <p className="md:text-medium line-clamp-1 md:line-clamp-2 text-[0.725rem] md:pt-2 pt-1">{data.metaDescription}</p>
                                            </div>
                                        </Link>
                                    </>
                                }>
                                    <Card className="rounded-t-[5px] rounded-b-none h-full">
                                        <CardHeader className="group relative w-full p-0 aspect-auto"
                                                    onMouseLeave={handleMouseLeave}>
                                            <Swipe className="w-full h-full"
                                                   onSwipeLeft={handleNext}
                                                   onSwipeRight={handlePrev}>
                                                <Link className="w-full" href={sharedService.getPostPath(data)}>
                                                    <NextImage
                                                        priority
                                                        quality={100}
                                                        loading="eager"
                                                        alt={data?.title}
                                                        layout="fill"
                                                        className="!w-full !relative object-contain !h-[unset]"
                                                        src={sharedService.isValidUrl(data.hotNewsPhoto) ? `${sharedService.getOptimizeImage(data.hotNewsPhoto, 'top-slider')}` : sharedService.getOptimizeImage(`${GlobalSettings.Settings.Server}/${data.hotNewsPhoto}`, 'top-slider')}
                                                    />
                                                </Link>
                                            </Swipe>
                                            <div className="hidden group-hover:block z-50">
                                                <IconButton
                                                    variant="text"
                                                    color="white"
                                                    size="lg"
                                                    onClick={handlePrev}
                                                    className={`max-w-[32px] max-h-[32px] md:max-w-[64px] md:max-h-[64px] !absolute top-1/2 left-0 -translate-y-2/4 bg-gray-600/60 ${animated ? 'animate-in slide-in-from-left-52 duration-500' : ''}`}
                                                >
                                                    <FaAngleLeft className="fill-white md:text-5xl text-2xl"/>
                                                </IconButton>
                                                <IconButton
                                                    variant="text"
                                                    color="white"
                                                    size="lg"
                                                    onClick={handleNext}
                                                    className={`max-w-[32px] max-h-[32px] md:max-w-[64px] md:max-h-[64px] !absolute top-1/2 !right-0 -translate-y-2/4 bg-gray-600/60 ${animated ? 'animate-in slide-in-from-right-52 duration-500' : ''}`}
                                                >
                                                    <FaAngleRight className="fill-white md:text-5xl text-2xl"/>
                                                </IconButton>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                </Tab>
                            );
                        }
                    })
                }
            </Tabs>
        </div>
    );
}
